<template>
  <div>
    <div class="nav-title">基本信息</div>
    <div class="panel" v-if="userData">
      <div class="row">
        <span class="label">头像：</span>
        <UploadImage :showUploadImg="false" @success="uploadAvatar($event)">
          <div class="avatar">
            <LoadImage class="img" :src="userInfo.headImg" :default="require('@/assets/images/avatar-default.png')"/>
            <div class="tips">点击编辑</div>
          </div>
        </UploadImage>
        
      </div>
      <div class="row">
        <span class="label">昵称：</span>
        <div class="flex items-center" v-if="!isEditName">
          <span>{{userInfo.nickName || '未填写'}}</span>
          <img class="icon-edit" src="@/assets/images/icon-edit-line.png" @click="isEditName = true">
        </div>
        <div class="flex items-center" v-else>
          <el-input type="text" placeholder="请输入" v-model="form.nickName"></el-input>
          <div class="btn-save btn-fill-theme" @click="setNickName">保存</div>
          <div class="btn-cancel" @click="cancelEditName">取消</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadImage from '@/components/LoadImage'
import UploadImage from '@/components/UploadImage'
import { userProfileApi } from '@/api/user.js'
import { mapState } from 'vuex'
export default {
  name: 'UserAccount',
  components: {
    LoadImage,
    UploadImage
  },
  data() {
    return {
      isEditName: false,
      userData: null,
      form: {
        nickName: '',
      }
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created() {
    this.getUserData()
  },
  methods: {
    getUserData() {
      userProfileApi({type: 'QUERY'}).then(res => {
        console.log('个人信息', res)
        this.userData = res.data
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    setNickName() {
      if(!this.form.nickName.trim()) return this.$tips({message: '昵称不能为空', type: 'warning'})
      userProfileApi({
        type: 'USER_NICK',
        value: this.form.nickName
      }).then(res => {
        this.isEditName = false
        this.form.nickName = ''
        this.$tips({message: res.msg, type: 'success'})
        this.$store.dispatch('GET_USER_INFO')
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    cancelEditName() {
      this.isEditName = false
      this.form.nickName = ''
    },
    uploadAvatar(e) {
      userProfileApi({
        'type': 'USER_HEAD',
				'value': e.ossUrl
      }).then(res => {
        this.$store.dispatch('GET_USER_INFO')
        this.$tips({message: res.msg, type: 'success'})
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-title {
  width: 96px;
  font-size: 16px;
  color: var(--theme-color);
  padding-bottom: 16px;
  border-bottom: 3px solid var(--theme-color);
  text-align: center;
}
.panel {
  padding: 0 24px;
}
.row {
  display: flex;
  align-items: center;
  height: 88px;
  font-size: 14px;
  border-bottom: 1px solid #F3F3F3;
  &:nth-last-child(1) {
    border-bottom: none;
  }
  .label {
    width: 80px;
    color: #65666F;
  }
  .avatar {
    position: relative;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    overflow: hidden;
    cursor: pointer;
    .img {
      width: 100%;
      height: 100%;
    }
    .tips {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 2px 0 8px;
      background-color: rgba(0,0,0,0.5);
      color: #fff;
      font-size: 10px;
      text-align: center;
    }
  }
  .icon-edit {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    cursor: pointer;
  }
  .btn-save, 
  .btn-cancel {
    width: 80px;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    border-radius: 4px;
    margin-left: 8px;
    text-align: center;
    cursor: pointer;
  }
  .btn-cancel {
    border: 1px solid #E8E8E8;
  }
}
</style>